import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { MDBDataTable } from 'mdbreact';
import { CSVLink } from "react-csv";
import dayjs from 'dayjs';
import { DatePicker, Select } from "antd";
import { currency, dateFormat, timeFormat } from "../../../../dynamicController";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import Loading from "../Loading";

const { RangePicker } = DatePicker;

const mySwal = withReactContent(Swal);

const UserPerformanceCard = ({ role, token, country, operators, distributors, tokenSetting, stamp }) => {

    const { t } = useTranslation();

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [userCountry, setUserCountry] = useState("")
    const [operatorId, setOperatorId] = useState(null)
    const [distributorId, setDistributorId] = useState(null)
    const [operatorOption, setOperatorOpion] = useState([])
    const [distributorOption, setDistributorOption] = useState([])

    useEffect(() => {
        if (operators) {
            let operatorList = []
            for (let i = 0; i < operators.length; i++) {
                let operatorObject = {
                    value: operators[i].id,
                    label: operators[i].username
                }
                operatorList.push(operatorObject)
            }
            setOperatorOpion(operatorList)
        }
    }, [operators])

    useEffect(() => {
        if (distributors) {
            let distributorList = []
            for (let i = 0; i < distributors.length; i++) {
                let distributorObject = {
                    value: distributors[i].id,
                    label: distributors[i].name
                }
                distributorList.push(distributorObject)
            }
            setDistributorOption(distributorList)
        }
    }, [distributors])

    useEffect(() => {
        if (role !== "client") {
            setLoading(true)
            axios
                .post("/api/admin/report/user", { startDate, endDate }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {
                    setData(res.data.data)
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleDate = (dates, dateStrings) => {
        if (dates) {
            setStartDate(dateStrings[0])
            setEndDate(dateStrings[1])
        } else {
            setStartDate("")
            setEndDate("")
        }
    };

    const handleReset = () => {
        setStartDate("")
        setEndDate("")
        setOperatorId(null)
        setDistributorId(null)
        setData([])
        setUserCountry("")
        if (role !== "client") {
            setLoading(true)
            axios
                .post("/api/admin/report/user", {}, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {
                    setData(res.data.data)
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });
        }
    }

    const handleFilter = () => {
        if (role === "client" && !distributorId && !operatorId) {
            mySwal.fire(t("Error"), t("Please select distributor or operator"), "error")
        } else {
            setLoading(true)
            axios
                .post("/api/admin/report/user", { startDate, endDate, operatorId, distributorId }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {
                    setData(res.data.data)
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });
        }
    }

    const handleChangeDistributor = (value) => {
        setDistributorId(value)
        setOperatorId(null)
        if (value) {
            setUserCountry(distributors.filter(distributor => distributor.id === value)[0].country)
        } else {
            setUserCountry("")
        }
    }

    const handleChangeOperator = (value) => {
        setOperatorId(value)
        setDistributorId(null)
        if (value) {
            let selectedOperator = operators.filter(operator => operator.id === value)[0]
            let selectedCountry = selectedOperator.country
            if (selectedOperator.distributorId) {
                let selectedDistributor = distributors.filter(distributor => distributor.id === selectedOperator.distributorId)[0]
                if (selectedDistributor) {
                    selectedCountry = selectedDistributor.country
                }
            }
            setUserCountry(selectedCountry)
        } else {
            setUserCountry("")
        }
    }

    let tableData = {
        columns: [
            {
                label: t("Name"),
                field: "userName",
                sort: "asc"
            },
            {
                label: t("Number"),
                field: "number",
                sort: "asc"
            },
            {
                label: t("Email"),
                field: "email",
                sort: "asc"
            },
            {
                label: t("Join Date"),
                field: "joinedDate",
                sort: "asc"
            },
            {
                label: t("Member ID"),
                field: "memberId",
                sort: "asc"
            },
            {
                label: t("Store"),
                field: "outlet",
                sort: "asc"
            },
            {
                label: t("Channel"),
                field: "channel",
                sort: "asc"
            },
            {
                label: (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                <ul>
                                    <li><b>{t("Registered")}</b>: {t("User registered but no transaction record")}</li>
                                    <li><b>{t("Active")}</b>: {t("User spending is less than")} {country || userCountry ? currency(country || userCountry) : ""}50</li>
                                    <li><b>{t("Tier 1")}</b>: {t("User spending is from")} {country || userCountry ? currency(country || userCountry) : ""}50 {t("until")} {country || userCountry ? currency(country || userCountry) : ""}199</li>
                                    <li><b>{t("Tier 2")}</b>: {t("User spending is")} {country || userCountry ? currency(country || userCountry) : ""}200 {t("and above")}</li>
                                </ul>
                            </Tooltip>
                        }
                    >
                        <span>{t("Tier")}</span>
                    </OverlayTrigger>
                ),
                field: "tier",
                sort: "asc"
            },
            {
                label: t("Referrer"),
                field: "referrer",
                sort: "asc"
            },
            {
                label: t("Referrals"),
                field: "referrals",
                sort: "asc"
            },
            {
                label: (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {t("Number of transactions for last 7 days")}
                            </Tooltip>
                        }
                    >
                        <span>{t("Number of Usage (in week)")}</span>
                    </OverlayTrigger>
                ),
                field: "weekUsage",
                sort: "asc"
            },
            {
                label: (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {t("Number of transactions for last 1 month")}
                            </Tooltip>
                        }
                    >
                        <span>{t("Number of Usage (in month)")}</span>
                    </OverlayTrigger>
                ),
                field: "monthUsage",
                sort: "asc"
            },
            {
                label: (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {t("Total amount spent in all the transactions")}
                            </Tooltip>
                        }
                    >
                        <span>{t("Total Spent")} {country || userCountry ? `(${currency(country || userCountry)})` : ""}</span>
                    </OverlayTrigger>
                ),
                field: "totalSpent",
                sort: "asc"
            },
            {
                label: t("Last Transaction Date"),
                field: "lastTransaction",
                sort: "asc"
            },
            {
                label: (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {t("Number of vouchers collected")}
                            </Tooltip>
                        }
                    >
                        <span>{t("Voucher Collected")}</span>
                    </OverlayTrigger>
                ),
                field: "voucherAcquired",
                sort: "asc"
            },
            {
                label: (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {t("Number of vouchers used")}
                            </Tooltip>
                        }
                    >
                        <span>{t("Voucher Spent")}</span>
                    </OverlayTrigger>
                ),
                field: "voucherUsed",
                sort: "asc"
            },
            {
                label: (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {t("Total number of washer machine transactions")}
                            </Tooltip>
                        }
                    >
                        <span>{t("Washer Used")}</span>
                    </OverlayTrigger>
                ),
                field: "washerUsed",
                sort: "asc"
            },
            {
                label: (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {t("Total number of dryer machine transactions")}
                            </Tooltip>
                        }
                    >
                        <span>{t("Dryer Used")}</span>
                    </OverlayTrigger>
                ),
                field: "dryerUsed",
                sort: "asc"
            }
        ],
        rows: data
    };

    let csv = []

    if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
            let dataObject = {
                ...data[i],
                number: `=""${data[i].number}""`,
            }
            csv.push(dataObject)
        }
    }

    const csvData = {
        headers: [
            { label: "Name", key: "userName" },
            { label: "Number", key: "number" },
            { label: "Email", key: "email" },
            { label: "Join Date", key: "joinedDate" },
            { label: "Member ID", key: "memberId" },
            { label: "Store", key: "outlet" },
            { label: "Channel", key: "channel" },
            { label: "Tier", key: "tier" },
            { label: "Referrer", key: "referrer" },
            { label: "Referrals", key: "referrals" },
            { label: "Number of Usage (in week)", key: "weekUsage" },
            { label: "Number of Usage (in month)", key: "monthUsage" },
            { label: `Total Spent ${country || userCountry ? `(${currency(country || userCountry)})` : ""}`, key: "totalSpent" },
            { label: "Last Transaction Date", key: "lastTransaction" },
            { label: "Voucher Collected", key: "voucherAcquired" },
            { label: "Voucher Spent", key: "voucherUsed" },
            { label: "Washer Used", key: "washerUsed" },
            { label: "Dryer Used", key: "dryerUsed" },
        ],
        data: csv
    };

    if (tokenSetting) {
        tableData.columns.splice(13, 0, {
            label: (
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip>
                            {t("Total number of e-Token spent in all the transactions")}
                        </Tooltip>
                    }
                >
                    <span>{t("e-Token Spent")}</span>
                </OverlayTrigger>
            ),
            field: "tokenSpent",
            sort: "asc"
        })

        tableData.columns.splice(14, 0, {
            label: (
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip>
                            {t("Current e-Token")}
                        </Tooltip>
                    }
                >
                    <span>{t("e-Tokens")}</span>
                </OverlayTrigger>
            ),
            field: "currentToken",
            sort: "asc"
        })

        csvData.headers.splice(13, 0, { label: "e-Token Spent", key: "tokenSpent" })
        csvData.headers.splice(14, 0, { label: "e-Tokens", key: "currentToken" })
    }

    if (stamp) {
        tableData.columns.push({
            label: (
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip>
                            {t("Total number of stamps collected")}
                        </Tooltip>
                    }
                >
                    <span>{t("Total Stamps")}</span>
                </OverlayTrigger>
            ),
            field: "totalStamps",
            sort: "asc"
        })

        csvData.headers.push({ label: "Total Stamps", key: "totalStamps" })
    }

    if (role === "client" || role === "distributor") {
        tableData.columns.unshift({
            label: t("Operator"),
            field: "operatorName",
            sort: "asc"
        })

        csvData.headers.unshift({ label: "Operator", key: "operatorName" },)
    }

    return (
        <div className="card">
            <div className="card-header">
                <h5 className="card-title">{t("User Performance")}</h5>
            </div>

            <div className="card-body">
                <div className="mb-5">
                    <h6>{t("Filter")}</h6>
                    {
                        role === "client" ? (
                            <div className="mb-3">
                                <label htmlFor="distributorId">{t("Select Distributor")}: </label>
                                <Select
                                    id="distributorId"
                                    name="distributorId"
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder={t("Select Distributor")}
                                    showSearch
                                    optionFilterProp="label"
                                    onChange={(value) => handleChangeDistributor(value)}
                                    options={distributorOption}
                                    value={distributorId}
                                />
                            </div>
                        ) : null
                    }

                    {
                        role === "client" ? (
                            <div className="mb-3">
                                <label htmlFor="operatorId">{t("Select Operator")}: </label>
                                <Select
                                    id="operatorId"
                                    name="operatorId"
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder={t("Select Operator")}
                                    showSearch
                                    optionFilterProp="label"
                                    onChange={(value) => handleChangeOperator(value)}
                                    options={operatorOption}
                                    value={operatorId}
                                />
                            </div>
                        ) : null
                    }

                    <div className="mb-3">
                        <label htmlFor="date">{t("Select Join Date")}: </label>
                        <RangePicker
                            id="date"
                            name="date"
                            placeholder={[t("Start date"), t("End date")]}
                            onChange={handleDate}
                            style={{ width: '100%' }}
                            value={startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : []}
                            presets={[
                                { label: t("Today"), value: [dayjs(), dayjs()] },
                                { label: t("Yesterday"), value: [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')] },
                                { label: t("Last 7 Days"), value: [dayjs().subtract(6, 'day'), dayjs()] },
                                { label: t("Last 30 Days"), value: [dayjs().subtract(29, 'day'), dayjs()] },
                                { label: t("This Month"), value: [dayjs().startOf('month'), dayjs().endOf('month')] },
                                { label: t("Last Month"), value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')] }
                            ]}
                        />
                    </div>

                    <div className="d-flex">
                        <button onClick={() => handleFilter()} className="btn btn-primary btn-inline form-control me-3">
                            {t("Filter")}
                        </button>
                        <button onClick={() => handleReset()} className="btn btn-secondary btn-inline form-control ms-3">
                            {t("Reset")}
                        </button>
                    </div>
                </div>

                <div className="text-center mt-5">
                    {
                        loading ? <Loading /> : (
                            <div>
                                <MDBDataTable data={tableData} noBottomColumns striped hover responsive bordered />
                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename={`user_performance_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
                                        {t("Download Report")}
                                    </CSVLink>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default UserPerformanceCard;