import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { MDBDataTable } from 'mdbreact';
import { CSVLink } from "react-csv";
import dayjs from 'dayjs';
import { DatePicker, Select } from "antd";
import { currency, dateFormat, timeFormat } from "../../../../dynamicController";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import Loading from "../Loading";

const { RangePicker } = DatePicker;

const mySwal = withReactContent(Swal);

const StorePerformanceCard = ({ role, token, country, operators, distributors, outlets }) => {

    const { t } = useTranslation();

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [userCountry, setUserCountry] = useState("")
    const [operatorId, setOperatorId] = useState(null)
    const [distributorId, setDistributorId] = useState(null)
    const [outletId, setOutletId] = useState([])
    const [operatorOption, setOperatorOpion] = useState([])
    const [distributorOption, setDistributorOption] = useState([])
    const [outletOption, setOutletOption] = useState([])

    useEffect(() => {
        if (outlets) {
            let outletList = []
            let filterOutlet = outlets
            if (distributorId) {
                filterOutlet = outlets.filter(outlet => outlet.distributorId === distributorId)
            }
            if (operatorId) {
                filterOutlet = outlets.filter(outlet => outlet.adminId === operatorId)
            }
            for (let i = 0; i < filterOutlet.length; i++) {
                let outletObject = {
                    value: filterOutlet[i].id,
                    label: `${filterOutlet[i].outlet_fullname} (${filterOutlet[i].outlet_name})`
                }
                outletList.push(outletObject)
            }
            setOutletOption(outletList)
        }
    }, [outlets, operatorId, distributorId])

    useEffect(() => {
        if (operators) {
            let operatorList = []
            for (let i = 0; i < operators.length; i++) {
                let operatorObject = {
                    value: operators[i].id,
                    label: operators[i].username
                }
                operatorList.push(operatorObject)
            }
            setOperatorOpion(operatorList)
        }
    }, [operators])

    useEffect(() => {
        if (distributors) {
            let distributorList = []
            for (let i = 0; i < distributors.length; i++) {
                let distributorObject = {
                    value: distributors[i].id,
                    label: distributors[i].name
                }
                distributorList.push(distributorObject)
            }
            setDistributorOption(distributorList)
        }
    }, [distributors])

    useEffect(() => {
        if (role !== "distributor" && role !== "client") {
            setLoading(true)
            axios
                .post("/api/admin/report/store", { startDate, endDate, outletId }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {
                    setData(res.data.data)
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleDate = (dates, dateStrings) => {
        if (dates) {
            setStartDate(dateStrings[0])
            setEndDate(dateStrings[1])
        } else {
            setStartDate("")
            setEndDate("")
        }
    };

    const handleReset = () => {
        setStartDate("")
        setEndDate("")
        setOperatorId(null)
        setDistributorId(null)
        setOutletId([])
        setData([])
        setUserCountry("")
        if (role !== "client" && role !== "distributor") {
            setLoading(true)
            axios
                .post("/api/admin/report/store", {}, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {
                    setData(res.data.data)
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });
        }
    }

    const handleFilter = () => {
        if (role === "client" && !distributorId && !operatorId && outletId.length <= 0) {
            mySwal.fire(t("Error"), t("Please select distributor, operator or outlet"), "error")
        } else {
            setLoading(true)
            axios
                .post("/api/admin/report/store", { startDate, endDate, operatorId, distributorId, outletId }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {
                    setData(res.data.data)
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });
        }
    }

    const handleChangeDistributor = (value) => {
        setDistributorId(value)
        setOperatorId(null)
        if (value) {
            setUserCountry(distributors.filter(distributor => distributor.id === value)[0].country)
        } else {
            setUserCountry("")
        }
    }

    const handleChangeOperator = (value) => {
        setOperatorId(value)
        setDistributorId(null)
        if (value) {
            let selectedOperator = operators.filter(operator => operator.id === value)[0]
            let selectedCountry = selectedOperator.country
            if (selectedOperator.distributorId) {
                let selectedDistributor = distributors.filter(distributor => distributor.id === selectedOperator.distributorId)[0]
                if (selectedDistributor) {
                    selectedCountry = selectedDistributor.country
                }
            }
            setUserCountry(selectedCountry)
        } else {
            setUserCountry("")
        }
    }

    const handleChangeOutlet = (value) => {
        setOutletId(value)
        if (!distributorId && !operatorId && role === "client") {
            let selectedOutlet = outlets.filter(outlet => outlet.id === value[0])[0]
            if (selectedOutlet) {
                if (selectedOutlet.distributorId) {
                    handleChangeDistributor(selectedOutlet.distributorId)
                } else {
                    handleChangeOperator(selectedOutlet.adminId)
                }
            }
        }
    }

    let tableData = {
        columns: [
            {
                label: t("Store"),
                field: "outletName",
                sort: "asc"
            },
            {
                label: t("Total Registered Users"),
                field: "totalUser",
                sort: "asc"
            },
            {
                label: (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {t("User registered but no transaction record")}
                            </Tooltip>
                        }
                    >
                        <span>{t("Registered")}</span>
                    </OverlayTrigger>
                ),
                field: "registered",
                sort: "asc"
            },
            {
                label: `${t("Registered")} (%)`,
                field: "registeredPercentage",
                sort: "asc"
            },
            {
                label: (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {t("User spending is less than")} {country || userCountry ? currency(country || userCountry) : ""}50
                            </Tooltip>
                        }
                    >
                        <span>{t("Active")}</span>
                    </OverlayTrigger>
                ),
                field: "active",
                sort: "asc"
            },
            {
                label: `${t("Active")} (%)`,
                field: "activePercentage",
                sort: "asc"
            },
            {
                label: (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {t("User spending is from")} {country || userCountry ? currency(country || userCountry) : ""}50 {t("until")} {country || userCountry ? currency(country || userCountry) : ""}199
                            </Tooltip>
                        }
                    >
                        <span>{t("Tier 1")}</span>
                    </OverlayTrigger>
                ),
                field: "tier1",
                sort: "asc"
            },
            {
                label: `${t("Tier 1")} (%)`,
                field: "tier1Percentage",
                sort: "asc"
            },
            {
                label: (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {t("User spending is")} {country || userCountry ? currency(country || userCountry) : ""}200 {t("and above")}
                            </Tooltip>
                        }
                    >
                        <span>{t("Tier 2")}</span>
                    </OverlayTrigger>
                ),
                field: "tier2",
                sort: "asc"
            },
            {
                label: `${t("Tier 2")} (%)`,
                field: "tier2Percentage",
                sort: "asc"
            },
            {
                label: (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {t("Average spending in each transaction")}
                            </Tooltip>
                        }
                    >
                        <span>{t("Average Spending")} {country || userCountry ? `(${currency(country || userCountry)})` : ""}</span>
                    </OverlayTrigger>
                ),
                field: "averageSpending",
                sort: "asc"
            },
            {
                label: (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {t("Total number of transactions")}
                            </Tooltip>
                        }
                    >
                        <span>{t("Total Transactions")}</span>
                    </OverlayTrigger>
                ),
                field: "totalTransactions",
                sort: "asc"
            },
            {
                label: (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {t("Number of vouchers used in all the transactions")}
                            </Tooltip>
                        }
                    >
                        <span>{t("Voucher Claimed")}</span>
                    </OverlayTrigger>
                ),
                field: "voucherClaimed",
                sort: "asc"
            },
            {
                label: (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {t("Total number of washer machine transactions")}
                            </Tooltip>
                        }
                    >
                        <span>{t("Washer Usage")}</span>
                    </OverlayTrigger>
                ),
                field: "washerUsed",
                sort: "asc"
            },
            {
                label: (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {t("Total number of dryer machine transactions")}
                            </Tooltip>
                        }
                    >
                        <span>{t("Dryer Usage")}</span>
                    </OverlayTrigger>
                ),
                field: "dryerUsed",
                sort: "asc"
            }
        ],
        rows: data
    };

    const csvData = {
        headers: [
            { label: "Store", key: "outletName" },
            { label: "Total Registered Users", key: "totalUser" },
            { label: "Registered", key: "registered" },
            { label: "Registered (%)", key: "registeredPercentage" },
            { label: "Active", key: "active" },
            { label: "Active (%)", key: "activePercentage" },
            { label: "Tier 1", key: "tier1" },
            { label: "Tier 1 (%)", key: "tier1Percentage" },
            { label: "Tier 2", key: "tier2" },
            { label: "Tier 2 (%)", key: "tier2Percentage" },
            { label: "Average Spending", key: "averageSpending" },
            { label: "Total Transactions", key: "totalTransactions" },
            { label: "Voucher Claimed", key: "voucherClaimed" },
            { label: "Washer Usage", key: "washerUsed" },
            { label: "Dryer Usage", key: "dryerUsed" },
        ],
        data
    };

    return (
        <div className="card">
            <div className="card-header">
                <h5 className="card-title">{t("Store Performance")}</h5>
            </div>

            <div className="card-body">
                <div className="mb-5">
                    <h6>{t("Filter")}</h6>
                    {
                        role === "client" ? (
                            <div className="mb-3">
                                <label htmlFor="distributorId">{t("Select Distributor")}: </label>
                                <Select
                                    id="distributorId"
                                    name="distributorId"
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder={t("Select Distributor")}
                                    showSearch
                                    optionFilterProp="label"
                                    onChange={(value) => handleChangeDistributor(value)}
                                    options={distributorOption}
                                    value={distributorId}
                                />
                            </div>
                        ) : null
                    }

                    {
                        role === "distributor" || role === "client" ? (
                            <div className="mb-3">
                                <label htmlFor="operatorId">{t("Select Operator")}: </label>
                                <Select
                                    id="operatorId"
                                    name="operatorId"
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder={t("Select Operator")}
                                    showSearch
                                    optionFilterProp="label"
                                    onChange={(value) => handleChangeOperator(value)}
                                    options={operatorOption}
                                    value={operatorId}
                                />
                            </div>
                        ) : null
                    }

                    <div className="mb-3">
                        <label htmlFor="outletId">{t("Select Outlet")}: </label>
                        <Select
                            mode="multiple"
                            id="outletId"
                            name="outletId"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder={t("Select Outlet")}
                            showSearch
                            optionFilterProp="label"
                            onChange={(value) => handleChangeOutlet(value)}
                            options={outletOption}
                            value={outletId}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="date">{t("Select Transaction Date")}: </label>
                        <RangePicker
                            id="date"
                            name="date"
                            placeholder={[t("Start date"), t("End date")]}
                            onChange={handleDate}
                            style={{ width: '100%' }}
                            value={startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : []}
                            presets={[
                                { label: t("Today"), value: [dayjs(), dayjs()] },
                                { label: t("Yesterday"), value: [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')] },
                                { label: t("Last 7 Days"), value: [dayjs().subtract(6, 'day'), dayjs()] },
                                { label: t("Last 30 Days"), value: [dayjs().subtract(29, 'day'), dayjs()] },
                                { label: t("This Month"), value: [dayjs().startOf('month'), dayjs().endOf('month')] },
                                { label: t("Last Month"), value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')] }
                            ]}
                        />
                    </div>

                    <div className="d-flex">
                        <button onClick={() => handleFilter()} className="btn btn-primary btn-inline form-control me-3">
                            {t("Filter")}
                        </button>
                        <button onClick={() => handleReset()} className="btn btn-secondary btn-inline form-control ms-3">
                            {t("Reset")}
                        </button>
                    </div>
                </div>

                <div className="text-center mt-5">
                    {
                        loading ? <Loading /> : (
                            <div>
                                <MDBDataTable data={tableData} noBottomColumns striped hover responsive bordered />
                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename={`store_performance_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
                                        {t("Download Report")}
                                    </CSVLink>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default StorePerformanceCard;