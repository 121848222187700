import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { MDBDataTable } from 'mdbreact';
import { CSVLink } from "react-csv";
import dayjs from 'dayjs';
import { DatePicker, Select } from "antd";
import { dateFormat, timeFormat } from "../../../../dynamicController";
import Loading from "../Loading";
import VoucherType from "../../pages/charts/VoucherType";
import VoucherValue from "../../pages/charts/VoucherValue";

const { RangePicker } = DatePicker;

const mySwal = withReactContent(Swal);

const VoucherRedemptionCard = ({ role, token, operators, distributors, outlets, tokenSetting, stamp }) => {

    const { t } = useTranslation();

    const [loading, setLoading] = useState(false)
    const [voucherTypeData, setVoucherTypeData] = useState([])
    const [voucherValueData, setVoucherValueData] = useState([])
    const [data, setData] = useState([])
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [operatorId, setOperatorId] = useState(null)
    const [distributorId, setDistributorId] = useState(null)
    const [outletId, setOutletId] = useState([])
    const [operatorOption, setOperatorOpion] = useState([])
    const [distributorOption, setDistributorOption] = useState([])
    const [outletOption, setOutletOption] = useState([])
    const [voucherTypeOption, setVouhcerTypeOption] = useState([])
    const [voucherType, setVoucherType] = useState(null)

    useEffect(() => {
        if (outlets) {
            let outletList = []
            let filterOutlet = outlets
            if (distributorId) {
                filterOutlet = outlets.filter(outlet => outlet.distributorId === distributorId)
            }
            if (operatorId) {
                filterOutlet = outlets.filter(outlet => outlet.adminId === operatorId)
            }
            for (let i = 0; i < filterOutlet.length; i++) {
                let outletObject = {
                    value: filterOutlet[i].id,
                    label: `${filterOutlet[i].outlet_fullname} (${filterOutlet[i].outlet_name})`
                }
                outletList.push(outletObject)
            }
            setOutletOption(outletList)
        }
    }, [outlets, operatorId, distributorId])

    useEffect(() => {
        if (operators) {
            let operatorList = []
            for (let i = 0; i < operators.length; i++) {
                let operatorObject = {
                    value: operators[i].id,
                    label: operators[i].username
                }
                operatorList.push(operatorObject)
            }
            setOperatorOpion(operatorList)
        }
    }, [operators])

    useEffect(() => {
        if (distributors) {
            let distributorList = []
            for (let i = 0; i < distributors.length; i++) {
                let distributorObject = {
                    value: distributors[i].id,
                    label: distributors[i].name
                }
                distributorList.push(distributorObject)
            }
            setDistributorOption(distributorList)
        }
    }, [distributors])

    useEffect(() => {
        if (role === "client") {
            let options = [
                { value: "Register", label: "Register" },
                { value: "Birthday", label: "Birthday" },
                { value: "Giveaway", label: "Giveaway" },
                { value: "Referral", label: "Referral" },
            ]
            if (tokenSetting) {
                options.push({ value: "Reload", label: "Reload" })
            }
            if (stamp) {
                options.push({ value: "Stamp", label: "Stamp" })
            }
            if (process.env.REACT_APP_STORETYPE === "cleanpro") {
                options.push({ value: "Voucher Code", label: "Voucher Code" })
            }
            setVouhcerTypeOption(options)
        }
    }, [role, stamp, tokenSetting])

    useEffect(() => {
        if (role !== "client") {
            setLoading(true)
            axios
                .post("/api/admin/report/voucher", { startDate, endDate, outletIds: outletId, voucherTypeFilter: voucherType }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {
                    setData(res.data.data)
                    setVoucherTypeData(res.data.voucherType)
                    setVoucherValueData(res.data.voucherValue)
                    let allVoucherType = res.data.voucherType.map(data => data.voucherType)
                    let options = []
                    for (let i = 0; i < allVoucherType.length; i++) {
                        options.push({ value: allVoucherType[i], label: allVoucherType[i] })
                    }
                    setVouhcerTypeOption(options)
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleDate = (dates, dateStrings) => {
        if (dates) {
            setStartDate(dateStrings[0])
            setEndDate(dateStrings[1])
        } else {
            setStartDate("")
            setEndDate("")
        }
    };

    const handleReset = () => {
        setStartDate("")
        setEndDate("")
        setOperatorId(null)
        setDistributorId(null)
        setOutletId([])
        setVoucherType(null)
        setData([])
        setVoucherTypeData([])
        setVoucherValueData([])
        if (role !== "client") {
            setLoading(true)
            axios
                .post("/api/admin/report/voucher", {}, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {
                    setData(res.data.data)
                    setVoucherTypeData(res.data.voucherType)
                    setVoucherValueData(res.data.voucherValue)
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });
        }
    }

    const handleFilter = () => {
        if (role === "client" && !distributorId && !operatorId && outletId.length <= 0) {
            mySwal.fire(t("Error"), t("Please select distributor, operator or outlet"), "error")
        } else {
            setLoading(true)
            axios
                .post("/api/admin/report/voucher", { startDate, endDate, operatorId, distributorId, outletIds: outletId, voucherTypeFilter: voucherType }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {
                    setData(res.data.data)
                    setVoucherTypeData(res.data.voucherType)
                    setVoucherValueData(res.data.voucherValue)
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });
        }
    }

    const handleChangeDistributor = (value) => {
        setDistributorId(value)
        setOperatorId(null)
    }

    const handleChangeOperator = (value) => {
        setOperatorId(value)
        setDistributorId(null)
    }

    const handleChangeOutlet = (value) => {
        setOutletId(value)
        if (!distributorId && !operatorId && role === "client") {
            let selectedOutlet = outlets.filter(outlet => outlet.id === value[0])[0]
            if (selectedOutlet) {
                if (selectedOutlet.distributorId) {
                    handleChangeDistributor(selectedOutlet.distributorId)
                } else {
                    handleChangeOperator(selectedOutlet.adminId)
                }
            }
        }
    }

    let tableData = {
        columns: [
            {
                label: t("Member ID"),
                field: "member_id",
                sort: "asc"
            },
            {
                label: t("User Name"),
                field: "name",
                sort: "asc"
            },
            {
                label: t("Number"),
                field: "number",
                sort: "asc"
            },
            {
                label: t("Email"),
                field: "email",
                sort: "asc"
            },
            {
                label: t("Amount"),
                field: "voucherAmount",
                sort: "asc"
            },
            {
                label: t("Grant Date"),
                field: "grantDate",
                sort: "asc"
            },
            {
                label: t("Claim Date"),
                field: "claimDate",
                sort: "asc"
            },
            {
                label: t("Status"),
                field: "available",
                sort: "asc"
            },
            {
                label: t("Voucher Type"),
                field: "from",
                sort: "asc"
            },
            {
                label: t("Washer Use"),
                field: "washer_use",
                sort: "asc"
            },
            {
                label: t("Dryer Use"),
                field: "dryer_use",
                sort: "asc"
            },
            {
                label: t("Package Use"),
                field: "package_use",
                sort: "asc"
            },
        ],
        rows: data
    };

    let csv = []

    if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
            let csvObject = {
                ...data[i],
                number: `=""${data[i].number}""`
            }
            csv.push(csvObject)
        }
    }

    const csvData = {
        headers: [
            { label: "Member ID", key: "member_id" },
            { label: "User Name", key: "name" },
            { label: "Number", key: "number" },
            { label: "Email", key: "email" },
            { label: "Amount", key: "voucherAmount" },
            { label: "Grant Date", key: "grantDate" },
            { label: "Claim Date", key: "claimDate" },
            { label: "Status", key: "available" },
            { label: "Voucher Type", key: "from" },
            { label: "Washer Use", key: "washer_use" },
            { label: "Dryer Use", key: "dryer_use" },
            { label: "Package Use", key: "package_use" },
        ],
        data: csv
    };

    return (
        <div className="card">
            <div className="card-header">
                <h5 className="card-title">{t("Voucher Redemption")}</h5>
            </div>

            <div className="card-body">
                <div className="mb-5">
                    <h6>{t("Filter")}</h6>
                    {
                        role === "client" ? (
                            <div className="mb-3">
                                <label htmlFor="distributorId">{t("Select Distributor")}: </label>
                                <Select
                                    id="distributorId"
                                    name="distributorId"
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder={t("Select Distributor")}
                                    showSearch
                                    optionFilterProp="label"
                                    onChange={(value) => handleChangeDistributor(value)}
                                    options={distributorOption}
                                    value={distributorId}
                                />
                            </div>
                        ) : null
                    }

                    {
                        role === "client" ? (
                            <div className="mb-3">
                                <label htmlFor="operatorId">{t("Select Operator")}: </label>
                                <Select
                                    id="operatorId"
                                    name="operatorId"
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder={t("Select Operator")}
                                    showSearch
                                    optionFilterProp="label"
                                    onChange={(value) => handleChangeOperator(value)}
                                    options={operatorOption}
                                    value={operatorId}
                                />
                            </div>
                        ) : null
                    }

                    <div className="mb-3">
                        <label htmlFor="outletId">{t("Select Outlet")}: </label>
                        <Select
                            mode="multiple"
                            id="outletId"
                            name="outletId"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder={t("Select Outlet")}
                            showSearch
                            optionFilterProp="label"
                            onChange={(value) => handleChangeOutlet(value)}
                            options={outletOption}
                            value={outletId}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="outletId">{t("Select Voucher Type")}: </label>
                        <Select
                            id="voucherType"
                            name="voucherType"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder={t("Select Voucher Type")}
                            showSearch
                            optionFilterProp="label"
                            onChange={(value) => setVoucherType(value)}
                            options={voucherTypeOption}
                            value={voucherType}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="date">{t("Select Grant Date")}: </label>
                        <RangePicker
                            id="date"
                            name="date"
                            placeholder={[t("Start date"), t("End date")]}
                            onChange={handleDate}
                            style={{ width: '100%' }}
                            value={startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : []}
                            presets={[
                                { label: t("Today"), value: [dayjs(), dayjs()] },
                                { label: t("Yesterday"), value: [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')] },
                                { label: t("Last 7 Days"), value: [dayjs().subtract(6, 'day'), dayjs()] },
                                { label: t("Last 30 Days"), value: [dayjs().subtract(29, 'day'), dayjs()] },
                                { label: t("This Month"), value: [dayjs().startOf('month'), dayjs().endOf('month')] },
                                { label: t("Last Month"), value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')] }
                            ]}
                        />
                    </div>

                    <div className="d-flex">
                        <button onClick={() => handleFilter()} className="btn btn-primary btn-inline form-control me-3">
                            {t("Filter")}
                        </button>
                        <button onClick={() => handleReset()} className="btn btn-secondary btn-inline form-control ms-3">
                            {t("Reset")}
                        </button>
                    </div>
                </div>

                <div className="text-center mt-5">
                    {
                        loading ? <Loading /> : (
                            <div>
                                <div className="d-flex">
                                    <div style={{ width: "50%" }}>
                                        <VoucherType voucherData={voucherTypeData} />
                                    </div>
                                    <div className="ps-5" style={{ width: "50%" }}>
                                        <VoucherValue voucherData={voucherValueData} />
                                    </div>
                                </div>

                                <MDBDataTable data={tableData} noBottomColumns striped hover responsive bordered />
                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename={`voucher_redemption_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
                                        {t("Download Report")}
                                    </CSVLink>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default VoucherRedemptionCard;